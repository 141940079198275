import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./TransitionCounter.module.scss";
import gsap from "gsap";
import { useRefStore } from "../../../store/ref-store";

export default function TransitionCounter() {
  const counterThreeRef = useRef(null);
  const counterTwoRef = useRef(null);
  const counterOneRef = useRef(null);

  const [counterAnimationComplete, setCounterAnimationComplete] = useState({
    one: false,
    two: false,
    three: false,
  });

  const checkAnimationsComplete = useCallback(() => {
    const { one, two, three } = counterAnimationComplete;

    if (one && two && three) {
      gsap.to(`.${styles.digit}`, {
        top: "-150px",
        stagger: 0.025,
        duration: 1.25,
        ease: "power4.out",
        onComplete: () => {
          useRefStore.setState({ counterTemp: false });
        },
      });
    }
  }, [counterAnimationComplete]);

  function animate(counter, duration, delay = 0, guid) {
    const numHeight = counter.querySelector("div").clientHeight;
    const totalDistance =
      (counter.querySelectorAll("div").length - 1) * numHeight;

    gsap.to(counter, {
      y: -totalDistance,
      duration: duration,
      delay: delay,
      ease: "power2.inOut",
      onComplete: () => {
        setCounterAnimationComplete((prev) => ({ ...prev, [guid]: true }));
      },
    });
  }

  useEffect(() => {
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < 10; j++) {
        const div = document.createElement("div");

        div.className = `${styles.num}`;

        div.textContent = j.toString();

        counterThreeRef.current.appendChild(div);
      }
    }

    const finalDiv = document.createElement("div");
    finalDiv.className = `${styles.num}`;
    finalDiv.textContent = "0";

    counterThreeRef.current.appendChild(finalDiv);

    animate(counterThreeRef.current, 4, 0, "three");
    animate(counterTwoRef.current, 5, 0, "two");
    animate(counterOneRef.current, 2, 3, "one");
  }, []);

  useEffect(() => {
    checkAnimationsComplete();
  }, [counterAnimationComplete, checkAnimationsComplete]);

  return (
    <div className={styles.counter}>
      <div
        className={`${styles.counterOne} ${styles.digit}`}
        ref={counterOneRef}
      >
        <div className={`${styles.num} ${styles.numOutline}`}>0</div>
        <div className={`${styles.num} ${styles.numOneOffset}`}>1</div>
      </div>

      <div
        className={`${styles.counterTwo} ${styles.digit}`}
        ref={counterTwoRef}
      >
        <div className={`${styles.num} ${styles.numOutline}`}>0</div>
        <div className={`${styles.num} ${styles.numTwoOffset}`}>1</div>
        <div className={styles.num}>2</div>
        <div className={styles.num}>3</div>
        <div className={styles.num}>4</div>
        <div className={styles.num}>5</div>
        <div className={styles.num}>6</div>
        <div className={styles.num}>7</div>
        <div className={styles.num}>8</div>
        <div className={styles.num}>9</div>
        <div className={styles.num}>0</div>
      </div>

      <div
        className={`${styles.counterThree} ${styles.digit}`}
        ref={counterThreeRef}
      >
        <div className={`${styles.num} ${styles.numOutline}`}>0</div>
        <div className={styles.num}>1</div>
        <div className={styles.num}>2</div>
        <div className={styles.num}>3</div>
        <div className={styles.num}>4</div>
        <div className={styles.num}>5</div>
        <div className={styles.num}>6</div>
        <div className={styles.num}>7</div>
        <div className={styles.num}>8</div>
        <div className={styles.num}>9</div>
      </div>
    </div>
  );
}
