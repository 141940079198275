import React from "react";
import styles from "./Transition.module.scss";
import { useRefStore } from "../../../store/ref-store";
import TransitionCounter from "../TransitionCounter/TransitionCounter";

export default function Transition() {
  const { firstRender } = useRefStore();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <svg
            width="255"
            height="309"
            viewBox="0 0 255 309"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50.1399 210.946H0L73.7961 105.705L0 0.480469H50.1399L123.953 105.705L50.1399 210.946Z"
              fill="#FBF5FF"
            />
            <path
              d="M257.656 309.457L257.656 268.254L123.155 268.254L123.155 309.457L257.656 309.457Z"
              fill="#FBF5FF"
              data-name={firstRender ? "Blink" : ""}
            />
          </svg>
        </div>
        {firstRender && <TransitionCounter />}
      </div>
    </div>
  );
}
